body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  height: calc(100 * var(--vh));
  width: 100%;
  max-width: 100%;
  position: fixed;
  overscroll-behavior-y: none;
  -webkit-user-drag: none;
  touch-action: manipulation;
  word-wrap: break-word;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gmnoprint > div {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 10px !important;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom
  .gmnoprint
  > div
  button
  > img {
  height: 13px !important;
  width: 13px !important;
}

@media screen and (max-width: 768px) {
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    display: none;
  }
}
